const races =[




    {
        title: 'FIS Telemark Worldcup Finals 2025',
        text: ' ',
        text2:'',
        text3:'',
        text4:'',
        text5:'',
        text6:'',
        text7: ' ',
        link:'',
        linkText:'',
        img: process.env.PUBLIC_URL + 'images/fis2024/affiche.png',
        category:'All',

    },




];

export default races;