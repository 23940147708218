import {Card, CardMedia, CardContent, Typography, Button} from '@mui/material';
import React, {useEffect, useState} from 'react';
import Box from "@mui/material/Box";
import ListRace from "../components/ListRace"
import ListAccess from "../components/ListAccess"
import ListResults from "../components/ListResults"
import ListSponsors from "../components/ListSponsors"


interface content {
img:string;
title:string;
text:string;
text2:string;
text3:string;
text4:string;
text5:string;
text6:string;
text7:string;
link:string;
linkText:string;
}




function ImageRace(activities:content) {


    const[checked,setChecked]=useState(true);
    useEffect(() => {
        setInterval(() => {
            setChecked(false);
        }, 20000);
    }, []);

    return (

        <Card  sx={{width:{sm:"100vh",lg:"100vh",xl:"100vh"}}} >
<Box
    sx={{display:"flex",
    justifyContent:"center",
    alignItems:"center",

   }}
>

</Box>
            <CardContent>
                <Typography fontSize="1.2rem" color="text.primary">

                    {activities.title}

                </Typography>
            </CardContent>


                <CardContent >
                    <Typography paragraph></Typography>
                    <Typography paragraph fontSize="1.1rem" color="text.secondary">
                       { activities.text}
                    </Typography>

                    {activities.text2 ?
                    <Typography paragraph fontSize="1.1rem" color="text.secondary">
                        {activities.text2}

                    </Typography>
                   :null }
                    {activities.text3 ?

                        <Typography paragraph fontSize="1.1rem" color="text.secondary">
                          { activities.text3}


                        </Typography>

                        :null }
                    {activities.text4 ?

                        <Typography paragraph fontSize="1.1rem" color="text.secondary">
                            { activities.text4}

                        </Typography>

                        :null }
                    {activities.text5 ?

                        <Typography paragraph fontSize="1.1rem" color="text.secondary">
                            { activities.text5}

                        </Typography>

                        :null }
                    {activities.text6 ?

                        <Typography paragraph fontSize="1.1rem" color="text.secondary">
                            { activities.text6}

                        </Typography>

                        :null }

                    {activities.link ?
                        <Button color="secondary" sx={{ m: 1, width: '25ch' }}
                                type="submit"
                                variant="contained" onClick={() => {

                            window.open(activities.link)
                        }}
                        >{activities.linkText}</Button>
                        :null }




                    {activities.text7 ?

                        <Typography paragraph fontSize="1.1rem" color="text.secondary">
                            { activities.text7}

                        </Typography>

                        :null }
                    {checked? (
                    <CardMedia
                        component="img"
                        width="auto"
                        height="auto"
                        image={activities.img}
                        alt={activities.img}
                    />):null}

                    <Typography paragraph fontSize="1.1rem" color="text.secondary">



                    </Typography>
                <Typography paragraph fontSize="1.1rem" color="text.secondary">
                    Programme:


                </Typography>
<ListRace/>
                    <Typography paragraph fontSize="1.1rem" color="text.secondary">



                    </Typography>
                    <Typography paragraph fontSize="1.1rem" color="text.secondary">
                        Accès:


                    </Typography>
                    <Typography paragraph fontSize="1.1rem" color="text.secondary">
                        L'aire d'arrivée des courses se trouve aux Collons 1900 (la Cambuse).


                    </Typography>

      <ListAccess/>
                    <Typography paragraph fontSize="1.1rem" color="text.secondary">



                    </Typography>
                    <Typography paragraph fontSize="1.1rem" color="text.secondary">
                        Résultats des courses et highlights journaliers:


                    </Typography>
                    <ListResults/>

                    <Typography paragraph fontSize="1.1rem" color="text.secondary">



                    </Typography>
                    <Typography paragraph fontSize="1.1rem" color="text.secondary">
                        Nous remercions nos partenaires pour leur soutien:


                    </Typography>
                    <Typography paragraph fontSize="1.1rem" color="text.secondary">



                    </Typography>
                    <img
                        src={"../images/Finals2025/BackdropBas.png"}
width={"100%"}
                    >
                    </img>

                </CardContent>


<ListSponsors/>



        </Card>


    );
};

export default ImageRace;
