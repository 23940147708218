import * as React from 'react';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, {
    AccordionSummaryProps,
    accordionSummaryClasses,
} from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import {Button} from "@mui/material";
import LinkIcon from '@mui/icons-material/Link';
import IconButton from "@mui/material/IconButton";
import InstagramIcon from "@mui/icons-material/Instagram";

const Accordion = styled((props: AccordionProps) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&::before': {
        display: 'none',
    },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
    <MuiAccordionSummary
        expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
        {...props}
    />
))(({ theme }) => {
    return ({
        backgroundColor: 'rgba(0, 0, 0, .03)',
        flexDirection: 'row-reverse',
        [`& .${accordionSummaryClasses.expandIconWrapper}.${accordionSummaryClasses.expanded}`]:
            {
                transform: 'rotate(90deg)',
            },
        [`& .${accordionSummaryClasses.content}`]: {
            marginLeft: theme.spacing(1),
        }
    });
});

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

export default function CustomizedAccordions() {
    const [expanded, setExpanded] = React.useState<string | false>('panel1');

    const handleChange =
        (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
            setExpanded(newExpanded ? panel : false);
        };

    return (
        <div>
            <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                    <Typography component="span">Résultats des courses: </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                        Live Timing, offert par Go Telemark est disponible avec le lien suivant.
                        <Button color="secondary" sx={{ m: 1, width: '25ch' }}
                                                                                type="submit"
                                                                                variant="contained" onClick={() => {

                        window.open("https://vola-publish.com/telemark/")
                    }}
                    >Live Timing</Button>
                    </Typography>
                </AccordionDetails>
                <AccordionDetails>
                    <Typography>
                        Résultats officiels:
                        <IconButton color="primary" aria-label="Instagram image" component="label"
                        >

                            <LinkIcon
                                onClick={() => {

                                    window.open("https://www.fis-ski.com/DB/general/event-details.html?sectorcode=TM&eventid=56637&seasoncode=2025")
                                }}
                            />
                        </IconButton>

                    </Typography>
                </AccordionDetails>


            </Accordion>
            <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
                    <Typography component="span">Film du jour:</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
Classic :  <IconButton color="primary" aria-label="Instagram image" component="label"
                    >

                        <LinkIcon
                            onClick={() => {

                                window.open("https://www.fis-ski.com/DB/general/event-details.html?sectorcode=TM&eventid=56637&seasoncode=2025")
                            }}
                        />
                    </IconButton>
                    </Typography>
                </AccordionDetails>
                <AccordionDetails>
                    <Typography>
Sprint: <IconButton color="primary" aria-label="Instagram image" component="label"
                    >

                        <LinkIcon
                            onClick={() => {

                                window.open("https://www.fis-ski.com/DB/general/event-details.html?sectorcode=TM&eventid=56637&seasoncode=2025")
                            }}
                        />
                    </IconButton>
                    </Typography>
                </AccordionDetails>
                <AccordionDetails>
                    <Typography>
                        Parallèle: <IconButton color="primary" aria-label="Instagram image" component="label"
                    >

                        <LinkIcon
                            onClick={() => {

                                window.open("https://www.fis-ski.com/DB/general/event-details.html?sectorcode=TM&eventid=56637&seasoncode=2025")
                            }}
                        />
                    </IconButton>
                    </Typography>
                </AccordionDetails>
                </Accordion>

        </div>
    );
}
