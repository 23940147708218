import * as React from 'react';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, {
    AccordionSummaryProps,
    accordionSummaryClasses,
} from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';

const Accordion = styled((props: AccordionProps) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&::before': {
        display: 'none',
    },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
    <MuiAccordionSummary
        expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
        {...props}
    />
))(({ theme }) => {
    return ({
        backgroundColor: 'rgba(0, 0, 0, .03)',
        flexDirection: 'row-reverse',
        [`& .${accordionSummaryClasses.expandIconWrapper}.${accordionSummaryClasses.expanded}`]:
            {
                transform: 'rotate(90deg)',
            },
        [`& .${accordionSummaryClasses.content}`]: {
            marginLeft: theme.spacing(1),
        }
    });
});

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

export default function CustomizedAccordions() {
    const [expanded, setExpanded] = React.useState<string | false>('panel1');

    const handleChange =
        (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
            setExpanded(newExpanded ? panel : false);
        };

    return (
        <div>
            <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                    <Typography component="span">Jeudi 27 mars 2025: Classic </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                        10h00: Départ course
                    </Typography>
                </AccordionDetails>
                <AccordionDetails>
                    <Typography>
                        12h00: Remise des fleurs (Aire arrivée)
                    </Typography>
                </AccordionDetails>
                <AccordionDetails>
                    <Typography>
                        16h00: Remise des prix et globes (Hotel Eringer)
                    </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
                    <Typography component="span">Vendredi 28 mars 2025: Sprint</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                        09h00: Départ manche 1
                    </Typography>
                </AccordionDetails>
                <AccordionDetails>
                    <Typography>
                        11h30: Départ manche 2
                    </Typography>
                </AccordionDetails>
                <AccordionDetails>
                    <Typography>
                        13h30: Remise des prix et globes (Aire arrivée)
                    </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
                    <Typography component="span">Samedi 29 mars 2025: Parallèle</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                        09h00: Départ Qualifications
                    </Typography>
                </AccordionDetails>
                <AccordionDetails>
                    <Typography>
                        11h30: Départ Finales
                    </Typography>
                </AccordionDetails>
                <AccordionDetails>
                    <Typography>
                        13h30: Remise des prix (Aire arrivée)
                    </Typography>
                </AccordionDetails>
                <AccordionDetails>
                    <Typography>
                        14h30: Remise du prix Go Telemark et globe parallèle (Aire arrivée)
                    </Typography>
                </AccordionDetails>
                <AccordionDetails>
                    <Typography>
                        18h30: Remise du globe du classement général et du classement des nations (Thyon 2000)
                    </Typography>
                </AccordionDetails>
            </Accordion>
        </div>
    );
}
