import * as React from 'react';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, {
    AccordionSummaryProps,
    accordionSummaryClasses,
} from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import {Button, CardMedia} from "@mui/material";


const Accordion = styled((props: AccordionProps) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&::before': {
        display: 'none',
    },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
    <MuiAccordionSummary
        expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
        {...props}
    />
))(({ theme }) => {
    return ({
        backgroundColor: 'rgba(0, 0, 0, .03)',
        flexDirection: 'row-reverse',
        [`& .${accordionSummaryClasses.expandIconWrapper}.${accordionSummaryClasses.expanded}`]:
            {
                transform: 'rotate(90deg)',
            },
        [`& .${accordionSummaryClasses.content}`]: {
            marginLeft: theme.spacing(1),
        }
    });
});

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

export default function CustomizedAccordions() {
    const [expanded, setExpanded] = React.useState<string | false>('panel1');

    const handleChange =
        (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
            setExpanded(newExpanded ? panel : false);
        };

    return (
        <div>
            <img
        src={"../images/Finals2025/Carte.jpg"}
        width={"100%"}>
        </img>
            <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                    <Typography component="span">En transport public: </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                        Bus 371 et Bus 372 au départ de Sion.
                        <Button color="secondary" sx={{ m: 1, width: '25ch' }}
                                                                                type="submit"
                                                                                variant="contained" onClick={() => {

                        window.open("https://www.sbb.ch/fr?date=%222025-03-16%22&moment=%22DEPARTURE%22&stops=%5B%7B%22value%22%3A%228501506%22%2C%22type%22%3A%22ID%22%2C%22label%22%3A%22Sion%22%7D%2C%7B%22value%22%3A%228502606%22%2C%22type%22%3A%22ID%22%2C%22label%22%3A%22Les%20Collons%2C%20Cambuse%22%7D%5D&time=%2212%3A15%22")
                    }}
                    >Horaires</Button>
                    </Typography>
                </AccordionDetails>
                <AccordionDetails>
                    <Typography>
                        Accès à pied de l'aire d'arrivée en 5 minutes.
                    </Typography>
                </AccordionDetails>


            </Accordion>
            <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
                    <Typography component="span">En voiture:</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                        Parkings disponibles à l'étage Les Collons 1900.
                    </Typography>
                </AccordionDetails>
                <AccordionDetails>
                    <Typography>
                        Accès à pied de l'aire d'arrivée en 5 minutes.
                    </Typography>
                </AccordionDetails>
                </Accordion>

        </div>
    );
}
