import * as React from 'react';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, {
    AccordionSummaryProps,
    accordionSummaryClasses,
} from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import LinkIcon from "@mui/icons-material/Link";
import IconButton from "@mui/material/IconButton";

const Accordion = styled((props: AccordionProps) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&::before': {
        display: 'none',
    },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
    <MuiAccordionSummary
        expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
        {...props}
    />
))(({ theme }) => {
    return ({
        backgroundColor: 'rgba(0, 0, 0, .03)',
        flexDirection: 'row-reverse',
        [`& .${accordionSummaryClasses.expandIconWrapper}.${accordionSummaryClasses.expanded}`]:
            {
                transform: 'rotate(90deg)',
            },
        [`& .${accordionSummaryClasses.content}`]: {
            marginLeft: theme.spacing(1),
        }
    });
});

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

export default function CustomizedAccordions() {
    const [expanded, setExpanded] = React.useState<string | true>('panel1');

    const handleChange =
        (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
            setExpanded(newExpanded ? panel : true);
        };

    return (
        <div>
            <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                    <Typography component="span">Partenaires principaux: </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                        La destination Thyon 4 Vallées
                        <IconButton color="primary" aria-label="Instagram image" component="label"
                        >

                            <LinkIcon
                                onClick={() => {

                                    window.open("https://www.thyon.ch")
                                }}
                            />
                        </IconButton>
                    </Typography>
                </AccordionDetails>
                <AccordionDetails>
                    <Typography>
                        La destination Hérémence Val d'Hérens
                        <IconButton color="primary" aria-label="Instagram image" component="label"
                        >

                            <LinkIcon
                                onClick={() => {

                                    window.open("https://www.heremence-tourisme.ch/")
                                }}
                            />
                        </IconButton>
                    </Typography>
                </AccordionDetails>
                <AccordionDetails>
                    <Typography>
                        Valais Wallis Promotion
                        <IconButton color="primary" aria-label="Instagram image" component="label"
                        >

                            <LinkIcon
                                onClick={() => {

                                    window.open("https://www.valais.ch/")
                                }}
                            />
                        </IconButton>
                    </Typography>
                </AccordionDetails>

                <AccordionDetails>
                    <Typography>
                        Association Go Telemark
                        <IconButton color="primary" aria-label="Instagram image" component="label"
                        >

                            <LinkIcon
                                onClick={() => {

                                    window.open("https://www.gotelemark.ch/")
                                }}
                            />
                        </IconButton>
                    </Typography>
                </AccordionDetails>
                <AccordionDetails>
                    <Typography>
                        Fond du Sport du Valais et la Loterie Romande
                        <IconButton color="primary" aria-label="Instagram image" component="label"
                        >

                            <LinkIcon
                                onClick={() => {

                                    window.open("https://sport.vs.ch/fonds-du-sport")
                                }}
                            />
                        </IconButton>
                    </Typography>
                </AccordionDetails>
                <AccordionDetails>
                    <Typography>
                        Les sponsors de Swiss-ski: Sunrise
                        <IconButton color="primary" aria-label="Instagram image" component="label"
                        >

                            <LinkIcon
                                onClick={() => {

                                    window.open("https://www.sunrise.ch")
                                }}
                            />
                        </IconButton>
                    </Typography>
                </AccordionDetails>
                <AccordionDetails>
                    <Typography>
                        Les sponsors de Swiss-ski: Raiffeisen
                        <IconButton color="primary" aria-label="Instagram image" component="label"
                        >

                            <LinkIcon
                                onClick={() => {

                                    window.open("https://www.raiffeisen.ch")
                                }}
                            />
                        </IconButton>
                    </Typography>
                </AccordionDetails>
                <AccordionDetails>
                    <Typography>
                        Les sponsors de Swiss-ski: Helvetia Assurances
                        <IconButton color="primary" aria-label="Instagram image" component="label"
                        >

                            <LinkIcon
                                onClick={() => {

                                    window.open("https://www.helvetia.ch")
                                }}
                            />
                        </IconButton>
                    </Typography>
                </AccordionDetails>
                <AccordionDetails>
                    <Typography>
                        Les sponsors de Swiss-ski: BKW
                        <IconButton color="primary" aria-label="Instagram image" component="label"
                        >

                            <LinkIcon
                                onClick={() => {

                                    window.open("https://www.bkw.ch")
                                }}
                            />
                        </IconButton>
                    </Typography>
                </AccordionDetails>
                <AccordionDetails>
                    <Typography>
                        Id-Inox
                        <IconButton color="primary" aria-label="Instagram image" component="label"
                        >

                            <LinkIcon
                                onClick={() => {

                                    window.open("https://www.id-inox.ch")
                                }}
                            />
                        </IconButton>
                    </Typography>
                </AccordionDetails>
                <AccordionDetails>
                    <Typography>
                        ESS Thyon- Les Collons
                        <IconButton color="primary" aria-label="Instagram image" component="label"
                        >

                            <LinkIcon
                                onClick={() => {

                                    window.open("https://thyon.swisskischool.ch/fr/")
                                }}
                            />
                        </IconButton>
                    </Typography>
                </AccordionDetails>

            <AccordionDetails>
                <Typography>
                    Hôtel Eringer Les Masses
                    <IconButton color="primary" aria-label="Instagram image" component="label"
                    >

                        <LinkIcon
                            onClick={() => {

                                window.open("https://eringerhotel.ch/")
                            }}
                        />
                    </IconButton>
                </Typography>
            </AccordionDetails>
                <AccordionDetails>
                    <Typography>
                        Eagle Valais
                        <IconButton color="primary" aria-label="Instagram image" component="label"
                        >

                            <LinkIcon
                                onClick={() => {

                                    window.open("https://eaglevalais.ch/")
                                }}
                            />
                        </IconButton>
                    </Typography>
                </AccordionDetails>
                <AccordionDetails>
                    <Typography>
                        Grande Dixence
                        <IconButton color="primary" aria-label="Instagram image" component="label"
                        >

                            <LinkIcon
                                onClick={() => {

                                    window.open("https://www.grande-dixence.ch/fr")
                                }}
                            />
                        </IconButton>
                    </Typography>
                </AccordionDetails>
                <AccordionDetails>
                    <Typography>
                        Genedis
                        <IconButton color="primary" aria-label="Instagram image" component="label"
                        >

                            <LinkIcon
                                onClick={() => {

                                    window.open("https://www.genedis.ch")
                                }}
                            />
                        </IconButton>
                    </Typography>
                </AccordionDetails>
                <AccordionDetails>
                    <Typography>
                        Prinoth
                        <IconButton color="primary" aria-label="Instagram image" component="label"
                        >

                            <LinkIcon
                                onClick={() => {

                                    window.open("https://www.prinoth.com")
                                }}
                            />
                        </IconButton>
                    </Typography>
                </AccordionDetails>
                <AccordionDetails>
                    <Typography>
                        Groupe Mutuel
                        <IconButton color="primary" aria-label="Instagram image" component="label"
                        >

                            <LinkIcon
                                onClick={() => {

                                    window.open("https://www.groupemutuel.ch")
                                }}
                            />
                        </IconButton>
                    </Typography>
                </AccordionDetails>
           </Accordion>
        </div>
    );
}
